import React, { useState, useEffect } from 'react'
import { globalHistory } from '@reach/router'
import { Link } from 'gatsby'
import gsap from 'gsap'

import UpWithCircle from '../assets/upcircle.svg'

const tl = gsap.timeline()

const Header = ({ dimensions }) => {
  const [menuState, setMenuState] = useState({ menuOpened: false })

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setMenuState({ menuOpened: false })
    })

    if (menuState.menuOpened === true) {
      tl
        .to('body', { duration: 0.01, css: { overflow: 'hidden' } })
        .to('.Main', { y: dimensions.width <= 654 ? '70vh' : dimensions.height / 2, ease: 'expo.inOut', duration: 0.6 })
        .to('.hamburger-menu span', { duration: 0.6, delay: -1, scaleX: 0, transformOrigin: '50% 0%', ease: 'expo.inOut' })
        .to('#Path_1', { duration: 0.4, delay: -0.6, css: { strokeDashoffset: 10, strokeDasharray: 5 } })
        .to('#Path_2', { duration: 0.4, delay: -0.6, css: { strokeDashoffset: 10, strokeDasharray: 20 } })
        .to('#Line_1', { duration: 0.4, delay: -0.6, css: { strokeDashoffset: 40, strokeDasharray: 18 } })
        .to('#circle', { duration: 0.6, delay: -0.8, css: { strokeDashoffset: 0 } })
        .to('.hamburger-menu-close', { duration: 0.6, delay: -0.8, css: { display: 'block' } })
    } else {
      tl
        .to('.Main', { y: 0, ease: 'expo.InOut', duration: 0.6 })
        .to('#circle', { duration: 0.6, delay: -0.6, css: { strokeDashoffset: -193, strokeDasharray: 277 } })
        .to('#Path_1', { duration: 0.4, delay: -0.6, css: { strokeDashoffset: 10, strokeDasharray: 10 } })
        .to('#Path_2', { duration: 0.4, delay: -0.6, css: { strokeDashoffset: 10, strokeDasharray: 10 } })
        .to('#Line_1', { duration: 0.4, delay: -0.6, css: { strokeDashoffset: 40, strokeDasharray: 40 } })
        .to('.hamburger-menu span', { duration: 0.6, delay: -0.6, scaleX: 1, transformOrigin: '50% 0%', ease: 'expo.inOut' })
        .to('.hamburger-menu-close', { css: { display: 'none' } })
        .to('body', { css: { overflow: 'auto' } })
    }
  }, [menuState.menuOpened])

  return (
    <div className="header">
      <div className="container">
        <div className="row v-center space-between">
          <div className="logo">
            <Link to="/">GrowBeyondCoaching</Link>
          </div>
          <div className="nav-toggle">
            <div onClick={() => setMenuState({ menuOpened: true })} className="hamburger-menu">
              <span></span>
              <span></span>
            </div>
            <div onClick={() => setMenuState({ menuOpened: false })} className="hamburger-menu-close">
              <UpWithCircle />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
