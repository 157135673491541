import React from 'react'
import { Link } from 'gatsby'

const Navigation = () => {
  return (
    <nav>
      <div className="container">
        <div className="nav-columns">
          <div className="nav-column">
            <ul className="nav-links">
              <li>
                <Link activeClassName='current' to='/'>
                  Home
                </Link>
              </li>
              <li>
                <Link activeClassName='current' to='/about'>
                  About
                </Link>
              </li>
              <li>
                <Link activeClassName='current' to='/contact'>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="nav-column">
            <div className="nav-infos">
              {/* <ul className="nav-info">
                <li className="nav-info-label">
                  Email
                </li>
                <li>
                </li>
              </ul> */}
              <ul className="nav-info">
                <li className="nav-info-label">
                  <Link activeClassName='current' to='/contact'>
                    Contact us
                  </Link>
                </li>
                <li>9AM-5PM Weekdays</li>
                <li>hello@growbeyondcoaching.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
