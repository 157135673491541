import React, { useEffect, useState } from 'react'
import gsap from 'gsap'

import '../styles/base.scss'

import Header from './Header'
import Navigation from '../components/Navigation'

const debounce = (fn, ms) => {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}
const Layout = ({ children }) => {
  let loaded = false
  const [dimensions, setDimensions] = useState({ height: 300, width: 100 })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!loaded) {
        // Prevents white flash
        gsap.to('body', 0, { css: { visibility: 'visible' } })
        loaded = true
      }

      // Get inner window height- for mobile
      const vh = window.innerHeight * 0.01
      // Sets CSS variable
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      const debouncedResize = debounce(() => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 1000)
      window.addEventListener('resize', debouncedResize)

      return () => {
        window.removeEventListener('resize', debouncedResize)
      }
    }
  }, [])
  return (
    <>
      <Header dimensions={dimensions} />
      <div className="Main">
        {children}
      </div>
      <Navigation />
    </>
  )
}

export default Layout
